export const casinoTabs = {
  roulettetab: {
    PortomasoRoulette: {
      href: "/casino/vivo-portomaso-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/portomasoroulette.png", alt: "" },
      name: "Portomaso Roulette",
    },
    OracleBlazeRoulette: {
      href: "/casino/vivo-galaxy-roulette",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/OracleBlazeRoulette.png", alt: "" },
      name: "Oracle Blaze Roulette",
    },
    OrientalRoulette: {
      href: "/casino/vivo-oriental-roulette",
      code: "1000011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/orientelroulette.png", alt: "" },
      name: "Oriental Roulette",
    },
    Oracle360Roulette: {
      href: "/casino/vivo-oracle-360-roulette",
      code: "1000312",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/oracle360roulette.png", alt: "" },
      name: "Oracle 360 Roulette",
    },
    EuropeanAutoRoulette: {
      href: "/casino/vivo-european-auto-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/vivo/Europeanautoroulette.png",
        alt: "",
      },
      name: "European Auto Roulette",
    },
    FrenchRoulette: {
      href: "/casino/	vivo-french-roulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/frenchrullete.png", alt: "" },
      name: "French Rulette",
    },
    LasVegasroulette1: {
      href: "/casino/vivo-las-vegas-roulette",
      code: 1000084,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/vivo/Lasvegasroulette.png", alt: "" },
      name: "Las Vegas Roulette",
    },
    VARoulette: {
      href: "/casino/vivo-va-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/vivo/VA-roulette.png",
        alt: "VA Roulette",
      },
    },

    namasteroulette: {
      href: "/casino/ezugi/namasteroulette",
      code: 221005,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino01.png", alt: "" },
      name: "namaste roulette",
    },
    UltimateRoulette: {
      href: "/casino/evolution/UltimateRoulette",
      code: "541000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino02.png", alt: "" },
      name: "Ultimate Roulette",
    },
    PortmasoRoulette2: {
      href: "/casino/ezg-portomaso-roulette-2",
      code: 611004,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino03.png", alt: "" },
      name: "portomaso roulette 2",
    },
    marinaroulette: {
      href: "/casino/ezugi/marinaroulette",
      code: 321000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino04.png", alt: "" },
      name: "marina roulette",
    },
    OracleRoulette360: {
      href: "/casino/ezugi/OracleRoulette360",
      code: "611003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino05.png", alt: "" },
      name: "Oracle Roulette 360",
    },
    diamondroulette: {
      href: "/casino/ezugi/diamondroulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino06.png", alt: "" },
      name: "diamond roulette",
    },
    speedroulette: {
      href: "/casino/ezugi/speedroulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino07.PNG", alt: "" },
      name: "speed roulette",
    },
    oracleRoulette: {
      href: "/casino/ezugi/oracleRoulette",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino08.png", alt: "" },
      name: "oracle Roulette",
    },
    AutoRoulette1: {
      href: "/casino/ezugi/AutoRoulette1",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino09.png", alt: "" },
      name: "Auto Roulette 1",
    },
    SpanishRoulette: {
      href: "/casino/ezg-spanish-roulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/ezugi/casino10.png",
        alt: "",
        className: "size_images",
      },
      name: "spanish roulette",
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/ezugi/casino11.png", alt: "" },
      name: "Prestige Auto Roulette",
    },

    Dragonroulette: {
      href: "/casino/xpg-dragon-roulette",
      code: "36",
      casino: "xpg",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/xpg/casino01.png", alt: "" },
      name: "dragon roulette",
    },
  },

  baccarattab: {
    MacauBaccarat: {
      href: "/casino/vivo-macau-baccarat",
      code: "1000145",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/maccaubaccarat.png", alt: "" },
      name: "MacauBaccarat",
    },
    VABaccarat4: {
      href: "/casino/vivo-va-bacarat-4",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/VA-baccrat.png", alt: "" },
      name: "VA Baccarat 4",
    },
    // HindiSpeedbaccarat: {
    //   href: "/casino/ezgevo-hindi-speed-baccarat",
    //   code: "120",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "hindi speed baccarat",
    //   cover: { src: "./images/baccarat/evolution/casino03.png", alt: "" },
    // },
    GalaxyBaccarat1: {
      href: "/casino/vivo-galaxy-baccarat-1",
      code: "1000012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/vivo/galaxybaccarat1.png", alt: "" },
      name: "Galaxy Baccarat 1",
    },

    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino01.png", alt: "" },
      name: "Marina baccarat",
    },
    noCommisionBaccaratEzugi: {
      href: "/casino/ezugi/noCommisionBaccaratEzugi",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino02.png", alt: "" },
      name: "no Commision Baccarat",
    },
    BaccaratEzugi: {
      href: "/casino/ezg-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino03.PNG", alt: "" },
      name: "baccarat",
    },
    SpeedCricketBaccarat: {
      href: "/casino/ezg-speed-cricket-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino04.png", alt: "" },
      name: "speed cricket baccarat",
    },
    VIPBaccarat: {
      href: "/casino/ezg-vip-fortune-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino05.png", alt: "" },
      name: "vip fortune baccarat",
    },
    Super6Baccarat: {
      href: "/casino/ezg-super-6-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino06.png", alt: "" },
      name: "super 6 baccarat",
    },
    KnockoutBaccarat: {
      href: "/casino/ezg-knockout-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/ezugi/casino07.png", alt: "" },
      name: "knockout baccarat",
    },

    Baccarat: {
      href: "/casino/sn-baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/supernowa/casino01.png", alt: "" },
      name: "Baccarat",
    },
    RNGBaccarat: {
      href: "/casino/sn-rng-baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/supernowa/casino02.png", alt: "" },
      name: "rng baccarat",
    },
  },

  AndarBahartab: {
    VivoAndarBahar: {
      href: "/casino/vivo-andar-bahar",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/vivo/andarbahar.png", alt: "" },
      name: "Andar Bahar",
    },

    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/ezugi/casino01.png", alt: "" },
      name: "Andar Bahar Live",
    },
    OTTAndarBahar: {
      href: "/casino/ezugi/OTTAndarBahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/ezugi/casino02.png", alt: "" },
      name: "OTT Andar Bahar",
    },
    UltimateAndarBahar: {
      href: "/casino/ezugi/UltimateAndarBahar",
      code: "228100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/ezugi/casino03.png", alt: "" },
      name: "Ultimate Andar Bahar",
    },

    AndarBaharSupernowa: {
      href: "/casino/sn-andar-bahar",
      code: "AB",
      casino: "wco",
      provider: "SN",
      name: "supernova andar bahar",
      homeUrl: "",
      cover: { src: "./images/andarbahar/supernowa/casino01.PNG", alt: "" },
      name: "supernowa andar bahar",
    },
    GoaAndarBahar: {
      href: "/casino/sn-goas-andar-bahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/supernowa/casino02.PNG", alt: "" },
      name: "goas andar bahar",
    },
    AndarBaharClassic: {
      href: "/casino/sn-classic-andar-bahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/supernowa/casino03.png", alt: "" },
      name: "supernowa classic andar bahar",
    },
  },

  TeenPattitab: {
    VivoCasinoHoldem: {
      href: "/casino/vivo-casino-holdem",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/vivo/casinoholdem.png", alt: "" },
      name: "Casino Hold'em",
    },
    VivoTeenPatti: {
      href: "/casino/vivo-teen-patti",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/vivo/teenpatti.png", alt: "" },
      name: "Teen Patti	",
    },

    ThreeCardPoker: {
      href: "/casino/ezgevo-three-card-poker",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/evolution/casino02.png", alt: "" },
      name: "three card poker",
    },

    TeenPatti03Card: {
      href: "/casino/ezg-teen-patti-3-card",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/ezugi/casino01.png", alt: "" },
      name: "teen patti 3 card",
    },
    CasinoHoldem: {
      href: "/casino/ezg-casino-holdem",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/ezugi/casino02.png", alt: "" },
      name: "casino holdem",
    },
    BetonTeenPattiEzugi: {
      href: "/casino/ezg-bet-on-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/ezugi/casino03.png", alt: "" },
      name: "bet on teen patti",
    },
    OneDayTeenPattiEzugi: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/ezugi/casino04.png", alt: "" },
      name: "one day teen patti",
    },
    OneDayTeenPattiClassicEzugi: {
      href: "/casino/ezugi/OneDayTeenPattiClassicEzugi",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/ezugi/casino05.png", alt: "" },
      name: "One Day Teen Patti Classic",
    },

    RNGTeenPatti2020Poker: {
      href: "/casino/sn-rng-teen-patti-2020",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/supernowa/casino01.png", alt: "" },
      name: "supernowa rng teen patti 2020",
    },
    RNGTeenPattiPoker: {
      href: "/casino/sn-rng-teen-patti",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/supernowa/casino02.png", alt: "" },
      name: "supernowa rng teen patti",
    },
    RNG3playerTeenPattiPoker: {
      href: "/casino/sn-rng-3-player-teen-patti",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/supernowa/casino03.png", alt: "" },
      name: "supernowa rng 3 player teen patti",
    },
    TeenPattiaceoffPoker: {
      href: "/casino/sn-teen-patti-face-off",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/supernowa/casino04.png", alt: "" },
      name: "supernowa teen patti face off",
    },
    TeenPatti2020Poker: {
      href: "/casino/sn-teen-patti-2020",
      code: "VPK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/supernowa/casino05.png", alt: "" },
      name: "supernowa teen patti 2020",
    },
  },

  Blackjacktab: {
    LimitlessBlackjack: {
      href: "/casino/vivo-limitless-blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Limitless Blackjack	",
      cover: { src: "./images/blackjack/vivo/limitlessblackjack.png", alt: "" },
    },
    BlackjackVip: {
      href: "/casino/platipus-blackjack-vip",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Vip",
      cover: { src: "./images/blackjack/vivo/BlackjackVIP.png", alt: "" },
    },
    LasVegasBlackjack: {
      href: "/casino/vivo-las-vegas-blackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Las Vegas Blackjack",
      cover: {
        src: "./images/blackjack/vivo/lasvegasblackjack.png",
        alt: "",
      },
    },
    BlackjackVivo: {
      href: "/casino/vivo-blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack ",
      cover: { src: "./images/blackjack/vivo/blackjackvivo.png", alt: "" },
    },
    blackjackVIP11: {
      href: "/casino/ezgevo-blackjack-vip-11",
      code: "51",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack vip 11",
      cover: { src: "./images/blackjack/evolution/casino05.png", alt: "" },
    },
    BlackjackSilverF: {
      href: "/casino/ezgevo-blackjack-silver-f",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack silver f",
      cover: { src: "./images/blackjack/evolution/casino06.png", alt: "" },
    },
    blackjackA: {
      href: "/casino/ezgevo-blackjack-a",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack A",
      cover: { src: "./images/blackjack/evolution/casino07.png", alt: "" },
    },

    BlackjackGold4: {
      href: "/casino/ezg-gold-blackjack-4",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold ",
      cover: { src: "./images/blackjack/ezugi/casino01.png", alt: "" },
    },
    BlackjackPlatinum1: {
      href: "/casino/ezg-platinium-blackjack-1",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      name: "platinum blackjack",
      cover: { src: "./images/blackjack/ezugi/casino02.png", alt: "" },
    },
    BlackjackGold3: {
      href: "/casino/ezg-gold-blackjack-3",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 3",
      cover: { src: "./images/blackjack/ezugi/casino03.png", alt: "" },
    },
    BlackjackGold1: {
      href: "/casino/ezg-gold-blackjack-1",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 1",
      cover: { src: "./images/blackjack/ezugi/casino04.png", alt: "" },
    },
    BlackjackGold5: {
      href: "/casino/ezg-gold-blackjack-5",
      code: "1000032",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 5",
      cover: { src: "./images/blackjack/ezugi/casino05.png", alt: "" },
    },
    BlackJackGold6: {
      href: "/casino/ezg-gold-blackjack-6",
      code: "1000031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Gold 6",
      cover: { src: "./images/blackjack/ezugi/casino06.png", alt: "" },
    },
    VIPSurrendeBlackjack: {
      href: "/casino/ezg-vip-surrender-blackjack",
      code: "422",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      name: "Vip surrender Blackjack",
      cover: { src: "./images/blackjack/ezugi/casino07.png", alt: "" },
    },
    BlackjackVIPDiamnd: {
      href: "/casino/ezg-vip-diamond-blackjack",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      name: "blackjack diamond vip",
      cover: { src: "./images/blackjack/ezugi/casino08.png", alt: "" },
    },

    PlaastinumBlackjacksss: {
      href: "/casino/ezugi/PlaastinumBlackjacksss",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      name: "Plaastinum Blackjack sss",
      cover: { src: "./images/blackjack/xpg/casino01.png", alt: "" },
    },
  },

  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card32/ezugi/casino01.png", alt: "" },
      name: "thirty two Cards",
    },

    thirtytwoCardss: {
      href: "/casino/sn-32-cards",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card32/supernowa/casino01.png", alt: "" },
      name: "thirty two Cardss",
    },
    RNG32cards: {
      href: "/casino/sn-rng-32-cards",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card32/supernowa/casino02.png", alt: "" },
      name: "supernowa rng 32 cards",
    },
  },

  sevenLuckytab: {
    luckyseven: {
      href: "/casino/ezugi/luckyseven",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/ezugi/casino01.png", alt: "" },
      name: "lucky seven",
    },

    Lucky7Supernowa: {
      href: "/casino/sn-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/supernowa/casino01.png", alt: "" },
      name: "supernowa lucky 7",
    },
    RNGLucky7: {
      href: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/supernowa/casino02.png", alt: "" },
      name: "supernowa rng lucky7",
    },
  },

  sicbotab: {
    supersicbo: {
      href: "/casino/evolution/supersicbo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/sicbo/evolution/casino01.png", alt: "" },
      name: "super sicbo",
    },
    LightingDice: {
      href: "/casino/ezgevo-lightning-dice",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/sicbo/evolution/casino02.png", alt: "" },
      name: "lightning dice",
    },

    ultimatesicbo: {
      href: "/casino/ezugi/ultimatesicbo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/sicbo/ezugi/casino01.png", alt: "" },
      name: "ultimate sicbo",
    },
    sicboezugi: {
      href: "/casino/ezg-sic-bo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/sicbo/ezugi/casino02.png", alt: "" },
      name: "sic bo",
    },

    sicbo: {
      href: "/casino/xpg-sicbo",
      code: "38",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/sicbo/xpg/casino01.png", alt: "" },
      name: "sicbo",
    },
  },

  gameShows: {
    CrazyTime: {
      href: "/casino/ezgevo-crazy-time",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/evolution/casino01.png", alt: "" },
      name: "crazy time",
    },
    deadorAliveSaloon: {
      href: "/casino/ezgevo-dead-or-alive-saloon",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/evolution/casino02.png", alt: "" },
      name: "dead or alive saloon",
    },
    Footballstudio: {
      href: "/casino/ezgevo-football-studio",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/evolution/casino03.png", alt: "" },
      name: "football studio",
    },
  },

  slotsgames: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-spin.png", alt: "" },
      name: "twin spin",
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzos-quest.png", alt: "" },
      name: "gonzo quest",
    },
    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardian-stones.png", alt: "" },
      name: "asgardian stones",
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets-of-atlantis.png", alt: "" },
      name: "secrets",
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/grand-wheel.png", alt: "" },
      name: "grand wheel",
    },
  },

  indianGames: {
    AndarBaharVivo: {
      href: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/indian/andarbahar-vivo.png",
        alt: "vivo andar bahar",
      },
      name: "vivo andar bahar",
    },
    TeenPattiVivo: {
      href: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/indian/teenpatti-vivo.png",
        alt: "vivo teen patti",
      },
      name: "vivo teen patti",
    },
    IndianNamasteRoulette: {
      href: "/casino/ezugi/IndianNamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/namaste-roulette.png", alt: "" },
      name: "Indian Namaste Roulette",
    },
    IndianTeenPatti: {
      href: "/casino/ezugi/IndianTeenPatti",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/teenpatti-3-card.png", alt: "" },
      name: "Indian Teen Patti",
    },
    onedayteenpatti: {
      href: "/casino/ezg-one-day-teen-patti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/oneday-teenpatti.png", alt: "" },
      name: "one day teen patti",
    },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/cards32.png", alt: "" },
      name: "32 cards",
    },
    beton: {
      href: "/casino/ezugi/beton",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/beton-teenpatti.png", alt: "" },
      name: "bet on",
    },
    oneday: {
      href: "/casino/ezugi/oneday",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/oneday-teenpatti-classic.png", alt: "" },
      name: "one day",
    },
  },

  spribeGames: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/mines.png", alt: "" },
      name: "mines",
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/goal.png", alt: "" },
      name: "goal",
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/dice.png", alt: "" },
      name: "dice",
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/aviator.png", alt: "" },
      name: "aviator",
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "",
      cover: { src: "./images/spribe/plinko.png", alt: "" },
      name: "plinko",
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "",
      cover: { src: "./images/spribe/mini-roulette.png", alt: "" },
      name: "mini roulette",
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "",
      cover: { src: "./images/spribe/hilo.png", alt: "" },
      name: "hilo",
    },
  },

  popularGames: {
    lucky7: {
      href: "/casino/ezugi/lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/lucky7.png", alt: "" },
      name: "lucky 7",
    },
    // Monopoly: {
    //   href: "/casino/evolution/Monopoly",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "EVO_LOBBY",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/02.jpg", alt: "" },
    //   name: "Monopoly",
    // },

    Dragontiger: {
      href: "/casino/ezugi/Dragontiger",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/dragon-tiger.png", alt: "" },
      name: "Dragon tiger",
    },
    Speed: {
      href: "/casino/ezugi/Speed",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/speed-roulette.png", alt: "" },
      name: "Speed",
    },
    teenpatti3card: {
      href: "/casino/ezugi/teenpatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/teenpatti-3-card.png", alt: "" },
      name: "teen patti 3 card",
    },
    // Miniroulette2: {
    //   href: "/casino/ezugi/Miniroulette2",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   name: "Mini roulette 2",
    //   cover: { src: "./images/popular/06.jpg", alt: "" },
    // },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/cards32.png", alt: "" },
      name: "32 cards",
    },
  },
};
