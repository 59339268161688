import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Modal, Nav, Navbar } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/games-icon/exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import LogoLightTheme from "../assets/images/LogoLightTheme.png";
import LogoDarkTheme from "../assets/images/LogoDarkTheme.png";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";

import Casino1 from "../assets/images/games-icon/casinoezugi-icon.png";
import Sports1 from "../assets/images/games-icon/evolution-icon.png";
import Slots1 from "../assets/images/games-icon/Slots.png";
import xpg from "../assets/images/games-icon/xpg1.png";
import supern from "../assets/images/games-icon/Supernova1.png";

import Evolution from "../assets/images/games-icon/Ezugi.png";
import SpribeIcon from "../assets/images/games-icon/aviator.png";
import StaticLogo from "../assets/images/staticlogo.png";

import BonusImg from "../assets/images/bonus.png";
import refferal from "../assets/images/refferal.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

import WhatsappIcon1 from "../assets/images/icons/whatsap.png";
import TelegramIcon1 from "../assets/images/icons/instagram.png";

import SportsbookIcon from "../assets/images/games-icon/sportsbook.png";
import exchange1 from "../assets/images/games-icon/exchange1.png";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);
  useEffect(() => {
    // dispatch(getExposureAmount());
    // dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  return (
    <header className="header aftrlgn">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {/* <div className="leftbar_toggle d-xl-none">
                  <Button
                    variant="primary"
                    onClick={() => setShowLeftbarToggle((s) => !s)}
                  >
                    <MenuIcon />
                  </Button>
                </div> */}
                <div className="logo">
                  <a href="/home">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img
                      src={LogoLightTheme}
                      alt="Logo Image"
                      className="light-theme"
                    />
                    <img
                      src={LogoDarkTheme}
                      alt="Logo Image"
                      className="dark-theme"
                    /> */}
                  </a>
                </div>
              </div>

              <div className="staticLogo">
                <img src={StaticLogo} />
              </div>

              <Navbar expand="xl" className="d-none d-xl-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={() => setShowToggle((s) => !s)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    <Nav.Link href="/home" className="logoImg d-xl-none">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img
                          src={LogoLightTheme}
                          alt="Logo Image"
                          className="dark-theme"
                        />
                        <img
                          src={LogoDarkTheme}
                          alt="Logo Image"
                          className="light-theme"
                        /> */}
                    </Nav.Link>

                    <Nav.Link href="/casino/ezugi">
                      <img src={Casino1} alt="bottom head link icon" />
                      <span>Ezugi</span>
                    </Nav.Link>
                    <Nav.Link href="/casino/evolution">
                      <img src={Evolution} alt="bottom head link icon" />
                      <span>Evolution</span>
                    </Nav.Link>
                    {/* <Nav.Link href="/sports/Cricket">
                      <img src={Exchange} alt="Exchange Icon" width={20} />{" "}
                      <span>Exchange</span>
                    </Nav.Link>
                    <Nav.Link href="/betby">
                      <img src={SportsbookIcon} alt="Sportsbook" />{" "}
                      <span>Sportsbook</span>
                    </Nav.Link> */}
                    <Nav.Link href="/Slots">
                      <img src={Slots1} alt="bottom head link icon" />
                      <span>Slots</span>
                    </Nav.Link>
                    <Nav.Link href="/casino/supernowa">
                      <img src={supern} alt="bottom head link icon" />
                      <span>Supernowa</span>
                    </Nav.Link>
                    <Nav.Link href="/casino/xpg">
                      <img src={xpg} alt="bottom head link icon" />
                      <span>Xpg</span>
                    </Nav.Link>
                    {/* <Nav.Link href="/Spribe">
                      <img src={SpribeIcon} alt="bottom head link icon" />
                      <span>Spribe</span>
                    </Nav.Link> */}
                    {/* <Nav.Link href="/cashier">
                      <CashierIcon /> Cashier
                    </Nav.Link> */}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div className="headerRight">
                <GamesSearchModal />
                <Dropdown align="end" className="me-2">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="tel:{user?.mobileno}">
                      <b>{user?.mobileno}</b>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Balance</h6>
                      <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => setShowBetModal(true)}
                    >
                      <h6>Cash</h6>
                      <span>INR {numberWithCommas(wallet?.cash)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Bonus</h6>
                      <span>INR {numberWithCommas(wallet?.coins)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/gatewaylist" className="deposit">
                      Deposit
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="funds me-2">
                  <ul>
                    {/* <li>
                      <div onClick={() => setShowBetModal(true)}>
                        <div>
                          Exp:{" "}
                          <span>{numberWithCommas(exposureAmount || 0)}</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </a>
                    </li> */}
                    <li className="d-none d-md-block">
                      <a href="/gatewaylist" className="deposit">
                        Deposit
                      </a>
                    </li>
                  </ul>
                </div>
                <Button variant="primary menu-btn" onClick={handleShow}>
                  <ProfileIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <a>{user?.mobileno}</a>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-1">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-1">
                {numberWithCommas(wallet?.cash || 0)}
              </div>
            </div>
            <div className="col-6">
              <div className="balance mb-3">Bonus</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                {numberWithCommas(wallet?.coins) || 0}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button className="btn_primary" variant="   w-100">
                Withdraw
              </Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button className="btn_primary" variant="primary w-100">
                Deposit
              </Button>
            </div>
            {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            {/* <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li> */}
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            <li>
              <a href="/bonus">
                <img src={BonusImg} />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/referral">
                <img src={refferal} />

                <span>Referral</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a
                className="panel social-icon watsapp"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={TelegramIcon1} alt="whatsappicon" />
              </a>
            </li>

            <li>
              <a
                className="panel social-icon watsapp"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <img src={WhatsappIcon1} alt="whatsappicon" />
              </a>
            </li>
            {/* <li className="theme-btn">
              <buton
                type="button"
                onClick={() => setShowLightModeToggle((s) => !s)}
              >
                <div className="theme-icon">
                  <img src={MoonLight} alt="Moon Icon" className="dark_theme" />
                  <img src={SunLight} alt="Sun Icon" className="light_theme" />
                </div>
              </buton>
            </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <div className="bottom_head d-none">
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <ul>
                {/* <li>
                  <a href="/casino/worldcasino">
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>All Live Games</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/sports/Cricket">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Exchange</span>
                  </a>
                </li> */}
                <li>
                  <a href="/casino/ezugi">
                    <img src={Casino1} alt="bottom head link icon" />
                    <span>Ezugi Games</span>
                  </a>
                </li>

                {/* <li>
                  <a href="/casino/worldcasino">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>From Casino</span>
                  </a>
                </li> */}
                <li>
                  <a href="Slots">
                    <img src={Slots1} alt="bottom head link icon" />
                    <span>Slots</span>
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution">
                    <img src={Evolution} alt="bottom head link icon" />
                    <span>Evolution</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default HeaderAfterLogin;
