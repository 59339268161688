import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Whatsapp from "../../../../assets/images/Whatsapp.svg";
import Telegram from "../../../../assets/images/Telegram.svg";
import Instagram from "../../../../assets/images/Instagram.svg";
import BackIcon from "../../../../assets/svg/BackIcon";
import ContactusImg from "../../../../assets/images/contactus-img.png";

const BeforeContactUs = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <Header />

      <main className="main">
        <div className="container">
          <div className="headingSec mt-4">
            <h4>Contact Us</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="contactus">
            <div className="row justify-content-center mt-4">
              <div className="col-xl-8 col-lg-10">
                <div className="row align-items-center">
                  <div class="contact-social col-md-6">
                    <h3>Get In Touch With Us:</h3>
                    <ul>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <div className="img-box">
                            <img src={Whatsapp} alt="Whatsapp Icon" />
                          </div>
                          <h5>Whats App</h5>
                        </a>
                      </li>

                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.INSTATGRAM != ""
                              ? appDetails?.INSTATGRAM
                              : "javascript:void(0)"
                          }
                          target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                        >
                          <div className="img-box">
                            <img src={Instagram} alt="Instagram Icon" />
                          </div>
                          <h5>Instagram</h5>
                        </a>
                      </li>
                      {/* <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.FACEBOOK != ""
                              ? appDetails?.FACEBOOK
                              : "javascript:void(0)"
                          }
                          target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                        >
                          <div className="img-box">
                            <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                          </div>
                          <h5>Facebook</h5>
                        </a>
                      </li> */}
                    </ul>
                  </div>

                  <div class="d-none d-md-flex justify-content-center col-md-6">
                    <div class="contact_img">
                      <img src={ContactusImg} alt="contact image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default BeforeContactUs;
