import React from "react";

export default function WalletIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.5"
      height="21.338"
      viewBox="0 0 24.5 21.338"
    >
      <g
        id="Group_1182"
        data-name="Group 1182"
        transform="translate(-502.925 -502.185)"
      >
        <path
          id="Rectangle_201"
          data-name="Rectangle 201"
          d="M2,0H7.28a0,0,0,0,1,0,0V6.635a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
          transform="translate(519.645 511.988)"
          fill="#facb2f"
          stroke="#facb2f"
          strokeWidth="1"
        />
        <path
          id="Path_2806"
          data-name="Path 2806"
          d="M521.211,526.9a1.567,1.567,0,0,1-1.567-1.567v-3.548a1.52,1.52,0,0,1,1.521-1.521h4.193v-2.92a2.212,2.212,0,0,0-2.212-2.212h-17.51a2.212,2.212,0,0,0-2.212,2.212v11.743a2.212,2.212,0,0,0,2.212,2.212h17.51a2.212,2.212,0,0,0,2.212-2.212V526.9Z"
          transform="translate(0 -8.28)"
          fill="none"
          stroke="#facb2f"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_140"
          data-name="Ellipse 140"
          cx="0.829"
          cy="0.829"
          r="0.829"
          transform="translate(521.119 514.384)"
        />
        <path
          id="Path_2807"
          data-name="Path 2807"
          d="M523.719,506.857l2.222-1.283-1.562-2.706-6.909,3.989Z"
          transform="translate(-9.478)"
          fill="none"
          stroke="#facb2f"
          strokeWidth="1"
        />
        <path
          id="Path_2808"
          data-name="Path 2808"
          d="M544.84,507.813l-2.037-3.528-6.111,3.528Z"
          transform="translate(-22.452 -0.956)"
          fill="none"
          stroke="#facb2f"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
