import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import BottomNav from "../../../../containers/BottomNav";
// import { pageUrlGenerator } from "../games/DynamicCasinoPage";
import { Navigate, useParams } from "react-router-dom";

const ExchangeSPMSportsLobbyPage = () => {
  const [lobbyUrl, setLobbyUrl] = useState();
  const { category } = useParams();
  console.log("category", category);
  useEffect(() => {
    try {
      if (localStorage.getItem("token") !== null) {
        // tab: z.string().optional(),
        // // Layout
        // headless: z.boolean().optional(),
        // sidebarType: z.nativeEnum(SIDERBAR_TYPES).optional(),
        // // Action URLS
        // homeUrl: z.string().url().optional(),
        // depositUrl: z.string().optional(),
        // signUpUrl: z.string().optional(),
        // signInUrl: z.string().optional(),
        // // Sports
        // sportsTypes: z.array(z.number()).optional(), // Allowed sportstypes

        const lobbyConfig = {
          config: {
            launchPage: category,
            theme: "slate-theme",
            // headless: true,
            // tab: "cricket", // soccer, tennis, live, upcomming, favourite,horse
            // homeUrl: pageUrlGenerator("/home"),
            // depositUrl: pageUrlGenerator("/gatewaylist"),
            // signUpUrl: pageUrlGenerator("/mobile-number"),
            // signUsignInUrlpUrl: pageUrlGenerator("/sign-in"),
          },
        };
        socket.emit("get:spmsports_lobby", lobbyConfig, (res) => {
          if (typeof res?.data?.lobby === "string") {
            setLobbyUrl(res?.data?.lobby);
          }
        });
      }
    } catch (err) {
      console.error(err);
      return;
    }
  }, [category]);

  return (
    <div className="live_casino_page live">
      <div className="header-bg">
        <HeaderAfterLogin />
      </div>

      {!Boolean(lobbyUrl) ? (
        <div className="custom_placeholder_parent">
          <div className="custom_placeholder_child">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
          </div>
        </div>
      ) : (
        <div className="exchange_iframe">
          <div>
            <iframe
              title="Exchange"
              src={lobbyUrl}
              frameBorder="0"
              style={{
                width: "100%",
                height: "calc(100vh - 70px)",
                // aspectRatio: "16/9",
              }}
              // scrolling="no"
              allowFullScreen={true}
            >
              Your browser doesn't support iFrames.
            </iframe>
          </div>
        </div>
      )}
      {/*  Iframe End */}

      <div>
        <BottomNav />
      </div>
    </div>
  );
};

export default ExchangeSPMSportsLobbyPage;
